import {KeyDisplay} from './utils';
import {CharacterControls} from './characterControls';
import * as THREE from 'three'
import {CameraHelper, Object3D} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import { CannonWorld } from './physics';



// SCENE
const scene = new THREE.Scene();
scene.background = new THREE.Color(0x000000);
var model: THREE.Group;
const ambientLight =  new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const dirLight = new THREE.DirectionalLight(0xffffff, 0.5)




let couchMesh: THREE.Object3D

// CAMERA
const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
camera.position.y = 70;
camera.position.z = 30;
camera.position.x = 70;
const cannonWorld = new CannonWorld(scene, ambientLight, dirLight)
// RENDERER
const renderer = new THREE.WebGLRenderer({antialias: true});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);
renderer.shadowMap.enabled = true

// CONTROLS
const orbitControls = new OrbitControls(camera, renderer.domElement);
orbitControls.enableDamping = true
orbitControls.minDistance = 5
orbitControls.maxDistance = 15
orbitControls.enablePan = false
orbitControls.maxPolarAngle = Math.PI / 2 - 0.05
orbitControls.update();

// LIGHTS
light()


// MODEL WITH ANIMATIONS
var characterControls: CharacterControls
new GLTFLoader().load('scene/Soldier.glb', function (gltf) {
    model = gltf.scene;
    model.traverse(function (object: any) {
        if (object.isMesh) {
            object.castShadow = true;
            // cannonWorld.addPhysics(object)
        }
    });
    model.scale.set(2, 2, 2)


    scene.add(model);

    // cannonWorld.renderWorld()
    const gltfAnimations: THREE.AnimationClip[] = gltf.animations;
    const mixer = new THREE.AnimationMixer(model);
    const animationsMap: Map<string, THREE.AnimationAction> = new Map()
    gltfAnimations.filter(a => a.name != 'TPose').forEach((a: THREE.AnimationClip) => {
        animationsMap.set(a.name, mixer.clipAction(a))
    })


    characterControls = new CharacterControls(model, mixer, animationsMap, orbitControls, camera, 'Idle', cannonWorld)
});

// CONTROL KEYS
const keysPressed = {}
const keyDisplayQueue = new KeyDisplay();
document.addEventListener('keydown', (event) => {
    keyDisplayQueue.down(event.key)
    if (event.shiftKey && characterControls) {
        characterControls.switchRunToggle()
    } else {
        (keysPressed as any)[event.key.toLowerCase()] = true
    }
}, false);
document.addEventListener('keyup', (event) => {
    keyDisplayQueue.up(event.key);
    (keysPressed as any)[event.key.toLowerCase()] = false
}, false);

const clock = new THREE.Clock();
let delta

// ANIMATE
function animate() {

    let mixerUpdateDelta = clock.getDelta();
    if (characterControls) {

        characterControls.update(mixerUpdateDelta, keysPressed);
    }

    delta = clock.getDelta()


cannonWorld.updateTVPosition()
    orbitControls.update()
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
}



document.body.appendChild(renderer.domElement);
animate();

// RESIZE HANDLER
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    keyDisplayQueue.updatePosition()
}

window.addEventListener('resize', onWindowResize);

function collision() {
    console.log('collision')
}

new GLTFLoader().load(
    'scene/roomexpanded.glb',
    (gltf) => {
        couchMesh = gltf.scene.children.find(child => child.name === 'Cube')
        gltf.scene.children.forEach( function (object: Object3D) {
            if(object.name === 'Cube') {
                console.log('This is a cube')
                cannonWorld.setOuterBox(object)
            }
            cannonWorld.addPhysics(object)
        });

        // if (typeof (couchMesh) !== 'undefined') {


        // couchBody.position.x = couchMesh.position.x + 4
        // couchBody.position.y = couchMesh.position.x + 4
        // couchBody.position.z = couchMesh.position.x + 4
        // }
        // const desk = gltf.scene.children.find(child => child.name === 'Desk')
        // const portalLightMesh = gltf.scene.children.find(child => child.name === 'portalLight')
        // const poleLightAMesh = gltf.scene.children.find(child => child.name === 'poleLightA')
        // const poleLightBMesh = gltf.scene.children.find(child => child.name === 'poleLightB')
        // gltf.scene.children = gltf.scene.children.filter( item => item !== bakedMesh)

        // bakedMesh.material = bakedMaterial
        // pillow.material = pillowTextureisometric2.glb
        // floor.material = bakedMaterial


        // portalLightMesh.material = portalLightMaterial
        // poleLightAMesh.material = poleLightMaterial
        // poleLightBMesh.material = poleLightMaterial
        const light = new THREE.AmbientLight(0x404040); // soft white light
        scene.add(light);
        console.log(gltf.scene)


        // spotlight.lookAt(0,0,0)
        // scene.add(backgroundMesh)

        gltf.scene.scale.set(2, 2, 2)
        scene.add(gltf.scene)
    }
)

function light() {

    dirLight.position.set(-60, 100, -10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 50;
    dirLight.shadow.camera.bottom = -50;
    dirLight.shadow.camera.left = -50;
    dirLight.shadow.camera.right = 50;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 200;
    dirLight.shadow.mapSize.width = 4096;
    dirLight.shadow.mapSize.height = 4096;
    scene.add(dirLight);
    // scene.add( new THREE.CameraHelper(dirLight.shadow.camera))
}