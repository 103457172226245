import * as CANNON from 'cannon-es'
import * as THREE from 'three'
import {FontLoader, Object3D, TextGeometry, TextureLoader, Vector3} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {CharacterControls} from './characterControls';


enum TV_STATE {
    RISING, SINKING, CONSTANT
}


export class CannonWorld {
    world: CANNON.World
    scene: THREE.Scene
    outerBox: Object3D
    ambientLight: THREE.AmbientLight
    dirLight: THREE.DirectionalLight
    objects = new Map<THREE.Object3D, CannonObject>();
    spotLight: THREE.SpotLight
    tv: THREE.Group
    clock = new THREE.Clock()
    tvState = TV_STATE.SINKING


    constructor(scene: THREE.Scene, ambientLight: THREE.AmbientLight, dirLight: THREE.DirectionalLight) {
        this.world = new CANNON.World()
        this.ambientLight = ambientLight
        this.dirLight = dirLight
        this.scene = scene
        this.world.gravity.set(0, 10, 0)
        this.spotLight = new THREE.SpotLight(0xffffff);
        this.spotLight.intensity = 0
        this.spotLight.castShadow = true;

        this.spotLight.shadow.mapSize.width = 100;
        this.spotLight.shadow.mapSize.height = 10;

        this.spotLight.shadow.camera.near = 500;
        this.spotLight.shadow.camera.far = 4000;
        this.spotLight.shadow.camera.fov = 30;


        scene.add(this.spotLight)
        this.loadTV(scene)
        console.log('logging tv')
        console.log(this.tv)


    }


    swapImage(group: THREE.Group, url: string) {
        this.tvState = TV_STATE.CONSTANT

        group.traverse(function (object: any) {
            if (object.material && object.material.name === 'display') {

                // object.rotateY(Math.PI/2)
                // object.rotateZ(Math.PI/2)
                let material = new TextureLoader().load(url)
                material.flipY = false
                object.material.map = material
                console.log(object.material)
            }

        });
    }

    loadTV(scene: THREE.Scene) {
        let _this = this
        new GLTFLoader().load('scene/tv.glb', function (gltf) {
            let tv = gltf.scene;


            tv.scale.set(3, 3, 3)
            console.log(tv)

            tv.position.y = -2

            _this.tv = tv
            scene.add(tv);

        });


    }

    updateTVPosition() {
        var delta = 1 / 10


        if (this.tv && this.tv.position) {

            switch (+this.tvState) {
                case TV_STATE.SINKING:
                    if (this.tv.position.y > -5) {
                        this.tv.position.y = this.tv.position.y - delta*3
                    }
                    break;
                case TV_STATE.CONSTANT:
                    break;
                case TV_STATE.RISING:
                    if (this.tv.position.y < 2.5) {
                        this.tv.position.y = this.tv.position.y + delta*3
                        this.spotLight.lookAt(this.tv.position)
                    }
                    break;
            }
        }
    }

    setOuterBox(outerBox: Object3D) {
        this.outerBox = outerBox
    }

    clearSpotLight() {
        this.spotLight.intensity = 0
        this.ambientLight.intensity = 0.8
        this.tvState = TV_STATE.SINKING

        // this.cube.position.y = -3
    }

    processMove(model: Object3D, newPosx: number, newPosz: number, characterControls: CharacterControls) {

        this.tv.quaternion.rotateTowards(characterControls.rotateQuarternion, 0.2)
        const boundingBox = new THREE.Box3().setFromObject(this.outerBox);

        if (boundingBox.containsPoint(new Vector3(newPosx, model.position.y, newPosz))) {
            let obstacleFree: boolean = true
            this.objects.forEach((value, key) => {
                const obstacle = new THREE.Box3().setFromObject(key);
                if (obstacle.containsPoint(new Vector3(newPosx, model.position.y + 1, newPosz))) {
                    if (key.name !== 'Cube') {
                        obstacleFree = false
                        console.log('blocked by ')
                        console.log(key)
                         this.ambientLight.intensity = 0.8


                        this.spotLight.position.set(newPosx, 15, newPosz);


                        this.swapImage(this.tv, 'textures/transaction.png')

                        this.tvState = TV_STATE.RISING

                        this.tv.position.z = newPosz
                        this.tv.position.x = newPosx
                        this.spotLight.intensity = 1
                    }
                } else {


                }
            })
            if (obstacleFree) {
                model.position.x = newPosx
                model.position.z = newPosz
            }
        }

    }

    //
    // if (newPosx < max.x && newPosx > min.x && newPosz < max.z && newPosz > min.z) {
    //     model.position.x = newPosx
    //     model.position.z = newPosz
    // }
    // else{
    //     console.log('hit the limit')
    // }


    renderWorld() {


    }

    addPhysics(object
                   :
                   THREE.Object3D
    ):
        void {
        let cannonObject = new CannonObject(object)
        this.objects.set(object, cannonObject)
        this.world.addBody(cannonObject.physicsBody)
        // this.scene.add(cannonObject.meshBoundingBox)


        console.log(cannonObject)
    }

}

export class CannonObject {
    targetObject: THREE.Object3D
    meshBoundingBox: THREE.Box3
    physicsBody: CANNON.Body


    constructor(object: THREE.Object3D) {
        this.meshBoundingBox = new THREE.Box3().setFromObject(object);
        this.targetObject = object
        this.createPhysicsBox()
    }

    createPhysicsBox() {
        const sizeX = this.calculateDistance(this.meshBoundingBox.min.x, this.meshBoundingBox.max.x)
        const sizeY = this.calculateDistance(this.meshBoundingBox.min.y, this.meshBoundingBox.max.y)
        const sizeZ = this.calculateDistance(this.meshBoundingBox.min.y, this.meshBoundingBox.max.y)

        const physicsBox: CANNON.Box = new CANNON.Box(new CANNON.Vec3(sizeX, sizeY, sizeZ))

        this.physicsBody = new CANNON.Body({mass: 120})
        this.physicsBody.addShape(physicsBox)

    }

    calculateDistance(point1: number, point2: number): number {
        if (point1 < 0 && point2 > 0)
            return Math.abs(point1) + Math.abs(point2)
        if (point1 > 0 && point2 < 0)
            return Math.abs(point1) + Math.abs(point2)
        else
            return Math.abs(point1) + Math.abs(point2)

    }
}


//
// this.cubeBody.quaternion.set(
//     this.model.quaternion.x,
//     this.model.quaternion.y,
//     this.model.quaternion.z,
//     this.model.quaternion.w
// )